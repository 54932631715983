var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "v-container",
              { staticClass: "content-container" },
              [
                _vm.sectionsData.headerSection
                  ? _c("hero-banner", {
                      attrs: {
                        title: _vm.sectionsData.headerSection.title,
                        subtitle: _vm.sectionsData.headerSection.subtitle,
                        button: _vm.sectionsData.headerSection.button,
                        image: _vm.sectionsData.headerSection.image,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "right",
                            fn: function () {
                              return [
                                _c("iframe", {
                                  attrs: {
                                    width: "560",
                                    height: "315",
                                    src: "https://www.youtube.com/embed/xcMiYiZ3X98?si=zs5UzX7g5nKdM5KC",
                                    title: "YouTube video player",
                                    frameborder: "0",
                                    allow:
                                      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                                    referrerpolicy:
                                      "strict-origin-when-cross-origin",
                                    allowfullscreen: "",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4282277710
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                1 === _vm.persona
                  ? _c("exam-feature", {
                      attrs: {
                        sectionData: _vm.sectionsData["featureSection"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.sectionsData["featureSection1"]
                  ? _c("exam-feature", {
                      attrs: {
                        sectionData: _vm.sectionsData["featureSection1"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                2 === _vm.persona
                  ? _c("exam-tips", {
                      attrs: {
                        sectionData: _vm.sectionsData["examTipsSection"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                2 === _vm.persona
                  ? _c("exam-feature", {
                      attrs: {
                        sectionData: _vm.sectionsData["featureSection"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                3 === _vm.persona || 4 === _vm.persona
                  ? _c("exam-timeline", { attrs: { persona: _vm.persona } })
                  : _vm._e(),
                _vm._v(" "),
                1 !== _vm.persona
                  ? _c("exams", {
                      attrs: { exams: _vm.exams, persona: _vm.persona },
                    })
                  : _vm._e(),
                _vm._v(" "),
                3 === _vm.persona || 4 === _vm.persona
                  ? _c("exam-tips", {
                      attrs: {
                        sectionData: _vm.sectionsData["examTipsSection"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                2 === _vm.persona
                  ? _c("exam-timeline", { attrs: { persona: _vm.persona } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.sectionsData["footerSection"]
              ? _c("exam-footer", {
                  attrs: {
                    persona: _vm.getPersona(),
                    freeTrialAvailable: _vm.freeTrialAvailable,
                    displayData: _vm.sectionsData["footerSection"],
                  },
                })
              : _vm._e(),
          ]
        : _c(
            "v-row",
            [
              _vm.loading
                ? _c(
                    "v-col",
                    { staticClass: "text-center my-12" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      [
        _c(
          "v-dialog",
          {
            attrs: { fullscreen: _vm.isMobileAndTablet, "max-width": "500px" },
            model: {
              value: _vm.modal,
              callback: function ($$v) {
                _vm.modal = $$v
              },
              expression: "modal",
            },
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  {
                    staticClass: "primary white--text",
                    class: {
                      headline: !_vm.isMobilePort,
                      "caption mobile-title": _vm.isMobilePort,
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "", align: "center" } },
                      [
                        _c("v-col", [
                          _vm._v(
                            "\n                            " +
                              _vm._s("Digital References Not Found") +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "accent2--text",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.modal = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                close\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c("v-container", { staticClass: "text-center py-12" }, [
                      _c("p", [
                        _vm._v(
                          "'The Digital References for this exam could not be found. Please contact the Assessment Center at \n                            '"
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:customersuccess@iccsafe.org",
                            },
                          },
                          [_vm._v("customersuccess@iccsafe.org")]
                        ),
                        _vm._v(
                          "' and provide them the exam name and the url below.'"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.url))]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }