var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "primaryLight text-center py-2",
          staticStyle: { "word-break": "normal" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _vm.tab === 0
                  ? _c("img", {
                      attrs: {
                        width: "auto",
                        height: "90",
                        src: _vm.asset(
                          require("../../images/banners/premium_answers.png")
                        ),
                        alt: "PREMIUMANSWERS",
                        title: "premiumanswers",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        width: "auto",
                        height: "90",
                        src: _vm.asset(
                          require("../../images/banners/quick_consult.jpg")
                        ),
                        alt: "Quick Consult Logo",
                        title: "Quick Consult",
                      },
                    }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tab === 0
        ? _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c("p", [
                _vm._v(
                  "\n            Premium Answers delivers carefully crafted answers to code compliance questions\n            from the ICC team of technical experts.  Search\n            "
                ),
                _c("b", [
                  _c(
                    "a",
                    { attrs: { href: _vm.getRoute("premium_answers") } },
                    [_vm._v("database of opinions")]
                  ),
                ]),
                _vm._v("\n            or use "),
                _c("b", [
                  _c("a", { attrs: { href: _vm.getRoute("quick_consult") } }, [
                    _vm._v("Quick Consult"),
                  ]),
                ]),
                _vm._v(
                  " to get advice on your\n            own custom question in 2 business days or less.\n        "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.blurbs, function (item, key) {
                return _c(
                  "v-row",
                  { key: key, attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v(
                            "\n                    mdi-check\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c("html-block", { attrs: { slug: "quick-ask" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "fs-16 mt-4 text-center font-weight-medium w-full",
                  },
                  [
                    _vm._v("\n                No information yet."),
                    _c("br"),
                    _vm._v("  Please check back later.\n            "),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("sample-answer-button", { staticClass: "mt-2" }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }