<template>
    <div>
        <search-header
            v-if="!loading"
            :total-results="pagination.totalResults"
            :search-term="search"
            :filters="filters"
            @showModal="() => filtersDialog = true"
            title-label="graphic">
        </search-header>
        <v-row v-if="loading">
            <v-col>
                <v-card
                    class="mt-4"
                    outlined>
                    <v-card-text>
                        <loading-linear>
                        </loading-linear>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col
                v-for="(item, key) in results"
                :key="key"
                sm="3">
                <v-card
                    class="fill-height"
                    @click="getGraphicDetails(item)"
                    outlined>
                    <div
                        @contextmenu="(event) => handleRightClick(event, item.document_sku)"
                        class="img-block-height"
                    >
                        <v-avatar
                            color="white"
                            size="40"
                            style="position: absolute; z-index: 2; top: 2px; right: 2px;">
                            <access-icon :access-level="getAccessLevel(item.document_sku, item.content_access_level_id)">
                            </access-icon>
                        </v-avatar>
                        <div class="text-center">
                            <img
                                :src="item.figure_url"
                                :alt="item.alt_text.length > 0 ? item.alt_text : `${item.section_title} Graphic`"
                                @error="loadPlaceholderImage"
                                height="160px"/>
                        </div>
                    </div>
                    <v-divider>
                    </v-divider>
                    <v-card-text>
                        <v-row
                            v-if="item.section_title"
                            dense>
                            <v-col>
                                <p class="body-2 mb-0 font-weight-bold">
                                    {{item.section_title}}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-tooltip
                                    v-if="!isMobilePort"
                                    bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            small
                                            color="primaryLight primary--text font-weight-bold"
                                            v-on="on">
                                            <div class="text-truncate">
                                                {{item.document_title}}
                                            </div>
                                        </v-chip>
                                    </template>
                                    <span class="tooltip-content">
                                        {{item.document_title}}
                                    </span>
                                </v-tooltip>
                                <v-chip
                                    v-else
                                    small
                                    color="primaryLight primary--text font-weight-bold">
                                    <div class="text-truncate">
                                        {{item.document_title}}
                                    </div>
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-if="hasMoreResults"
            dense
            justify="center">
            <v-col cols="auto">
                <v-btn
                    text
                    color="primary"
                    class="mb-4"
                    :loading="fetchingResults"
                    @click="showMore">
                    Show More
                    <v-icon right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <confirm-dialog
            :is-open="premiumRequiredDialog"
            :width="600"
            border-style
            :buttons="false"
            @closed="() => this.premiumRequiredDialog = false">
            <template v-slot:title>
                <v-icon
                    class="mr-3"
                    color="accent2">
                    mdi-creation
                </v-icon>
                <span class="font-weight-bold">
                    Subscribe to Premium
                </span>
            </template>
            <template v-slot:default>
                <div class="text-center">
                    <img
                        class="mx-auto"
                        :src="asset(require('../../../images/graphics_placeholder.png'))"
                        alt="ICC Digital Codes Premium" />
                </div>
                <v-row>
                    <v-col>
                        <h3 class="font-weight-regular text-center my-4">
                            You need a Premium subscription to <span class="bold">{{graphicDetails.document_title}}</span> to view this graphic.
                        </h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        class="text-center">
                        <v-btn
                            class="mx-1"
                            outlined
                            depressed
                            @click="redirectToLearnMore()">
                            Learn More
                        </v-btn>
                        <v-btn
                            class="mx-1"
                            depressed
                            color="accent2"
                            @click="openSubscribeToPremiumModal(graphicDetails.document_id)">
                            Subscribe To Premium
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </confirm-dialog>
        <confirm-dialog
            :is-open="dialog"
            :width="800"
            @closed="() => this.dialog = false">
            <template v-slot:title>
                {{ graphicDetails.section_title }}
            </template>
            <template v-slot:default>
                <v-container>
                    <section class="view-graphic-modal__section">
                        <div
                            @contextmenu="(event) => handleRightClick(event, graphicDetails.document_sku)"
                            class="view-graphic-model__image pb-2"
                        >
                            <v-img
                                :src="graphicDetails.figure_url"
                                :alt="graphicDetails.alt_text.length > 0 ? graphicDetails.alt_text : `${graphicDetails.section_title} Graphic`"
                                max-height="300px"
                                style="max-height: 40vh; width: auto; object-fit: contain;"
                                contain
                                @error="loadPlaceholderImage"
                            />
                        </div>
                        <div class="view-graphic-modal__content v-card v-sheet theme--light v-card--flat mt-4 pa-3 rounded-lg section-border">
                            <div class="view-graphic-modal__content-body">
                                <div v-if="dialogLoading">
                                    <loading-linear>
                                    </loading-linear>
                                </div>
                                <div
                                    v-else
                                    v-html="graphicDetails.content">
                                </div>
                            </div>
                            <div v-if="isNotEmptyObject(graphicDetails.breadcrumbs)">
                                <item-breadcrumb
                                    class="mt-2"
                                    :meta="graphicDetails"
                                    :documentId="graphicDetails.document_id"
                                    :breadcrumbs="graphicDetails.breadcrumbs">
                                </item-breadcrumb>
                            </div>
                        </div>
                    </section>
                </v-container>
            </template>
            <template v-slot:buttons>
                <v-spacer>
                </v-spacer>
                <v-btn
                    outlined
                    color="primary"
                    @click="dialog = false">
                    Close
                </v-btn>
                <a
                    :href="redirectLink"
                    @click="logSearchClick(actionTypeMessage.jumpToSection)"
                    target="_blank">
                    <v-btn
                        class="ml-2"
                        :loading="dialogLoading"
                        color="primary">
                        Jump to Section
                        <v-icon
                            right>
                            mdi-open-in-new
                        </v-icon>
                    </v-btn>
                </a>
            </template>
        </confirm-dialog>
        <v-dialog
            v-model="filtersDialog"
            fullscreen
            scrollable>
            <v-card>
                <v-card-title>
                    <h3>Graphics Filters</h3>
                    <v-spacer></v-spacer>
                    <v-icon
                        @click="filtersDialog = false">
                        close
                    </v-icon>
                </v-card-title>
                <v-card-text class="pa-4">
                    <graphics-search-filters>
                    </graphics-search-filters>
                </v-card-text>
                <v-card-actions>
                    <v-row dense>
                        <v-col>
                            <v-btn
                                block
                                outlined
                                @click="filtersDialog = false"
                                color="primary">
                                Close
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import SearchHeader from "../titles/SearchHeader.vue";
    import {GraphicsApi} from "../../../api/GraphicsApi";
    import AccessIcon from "../../icons/AccessIcon.vue";
    import ConfirmDialog from "../../ConfirmDialog.vue";
    import LoadingLinear from "../../loaders/LoadingLinear.vue";
    import ItemBreadcrumb from "../content/result/item_breadcrumb.vue";
    import {EventNames} from "../../../classes/EventNames";
    import TabbedSearch from "../mixins/TabbedSearch";
    import ItemLink from "../content/result/item_link.vue";
    import GraphicsSearchFilters from "./filters/GraphicsSearchFilters.vue";
    import Mobile from "../../inc/mixins/mobile";

    export default {
        name: 'GraphicsSearchResults',
        data() {
            return {
                search: '',
                page: 1,
                limit: 4,
                results: [],
                pagination: {
                    currentPage: 1,
                    totalResults: 0,
                    maxPerPage: 0
                },
                categories: [],
                filters: {
                    categories: [],
                    years: []
                },
                loading: true,
                fetchingResults: true,
                dialogLoading: false,
                dialog: false,
                filtersDialog: false,
                premiumRequiredDialog: false,
                graphicDetails: {},
                resetAllData: false,
                searchTermId: null,
                accessLevelMsg: {
                    basic: 'Basic',
                    premium: 'Premium Required',
                    premiumActive: 'Premium Active'
                },
                actionTypeMessage: {
                    resultOpen: 'Search result opened',
                    jumpToSection: 'Jump to section clicked',
                    subscribeToPremiumClick: 'Subscribe to premium button clicked',
                    learnMoreBtnClick:'Learn more button clicked'
                }
            }
        },
        mixins: [
            TabbedSearch,
            Mobile
        ],
        components: {
            GraphicsSearchFilters,
            ItemLink,
            ItemBreadcrumb,
            LoadingLinear,
            ConfirmDialog,
            AccessIcon,
            SearchHeader
        },
        watch: {
            search(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$store.commit('setTabbedSearchValues', {
                        tab: this.$getConst('graphicsSearchType'),
                        searchValue: newVal
                    });
                }
            },
            filterCategories: {
                handler() {
                    if (this.resetAllData) {
                        return;
                    }
                    this.refreshData();
                },
                deep: true
            },
            filterYears: {
                handler() {
                    if (this.resetAllData) {
                        return;
                    }
                    this.refreshData();
                },
                deep: true
            },
            filterTitleIds: {
                handler() {
                    if (this.resetAllData) {
                        return;
                    }
                    this.refreshData();
                },
                deep: true
            },
        },
        computed: {
            redirectLink() {
                return Routing.generate('content_id_redirect', {
                    'contentId': this.graphicDetails.content_id
                });
            },
            hasMoreResults() {
                return this.results.length > 0 && this.results.length < this.pagination.totalResults;
            }
        },
        methods: {
            showMore(){
                this.page = this.page + 1;
                this.getResults();
            },
            async getResults() {
                if (!this.isGraphicsTab()) {
                    return;
                }
                this.fetchingResults = true;

                const categories = this.filterCategories[this.$getConst('graphicsSearchType')] || [];
                const years = this.filterYears[this.$getConst('graphicsSearchType')] || [];
                const titles = this.filterTitleIds[this.$getConst('graphicsSearchType')] || [];
                const data = {
                    search: this.search,
                    titles: titles,
                    page: this.page,
                    limit: this.limit,
                    years: years,
                    categories: categories
                }

                const url = new URL(window.location);
                const params = new URLSearchParams(url.search);

                // Update search term in the URL
                if (this.search) {
                    params.set('searchTermAny', this.search);
                } else {
                    params.delete('searchTermAny');
                }

                params.delete('categories');
                params.delete('years');
                params.delete('titles');

                categories.forEach(v => params.append('categories', v));
                years.forEach(v => params.append('years', v));
                titles.forEach(v => params.append('titles', v));

                // Update the URL without reloading the page
                window.history.pushState({}, "", `?${params.toString()}`);

                this.$store.commit('setTabbedSearchUrl', {
                    tab: this.$getConst('graphicsSearchType'),
                    searchUrl: `?${params.toString()}`,
                    updateSearchTerm: true
                });

                try {
                    const response = await GraphicsApi.getGraphicsResults(data);
                    const newData = response.data.graphics;
                    this.results.push(...newData);
                    this.pagination = response.data.pagination;
                    this.loading = false;
                    this.fetchingResults = false;
                    this.searchTermId = response.data.searchTermId;
                } catch (error) {
                    // Todo: Handle error
                    console.log(error)
                }
                this.resetAllData = false;
            },
            async getGraphicDetails(item) {
                this.graphicDetails = item;
                this.graphicDetails.breadcrumbs = {};
                if (this.getAccessLevel(item.document_sku, item.content_access_level_id) === 'premium') {
                    this.premiumRequiredDialog = true;
                } else {
                    this.dialogLoading = true;
                    this.dialog = true;

                    try {
                        const data = {
                            documentId: item.document_id,
                            contentId: item?.content_id
                        }
                        const response = await GraphicsApi.getFigureContent(data);
                        this.graphicDetails.content = this.removeAllImagesFromDom(response.content);
                        this.graphicDetails.breadcrumbs = response.breadcrumbs;
                    } finally {
                        this.dialogLoading = false;
                    }
                }
                this.logSearchClick(this.actionTypeMessage.resultOpen);
            },
            isNotEmptyObject(obj) {
                return obj && Object.keys(obj).length > 0;
            },
            removeAllImagesFromDom(content) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');
                if (doc.querySelector("parsererror")) {
                    return content;
                }
                const images = doc.querySelectorAll('img');
                images.forEach(img => img.remove());
                return doc.documentElement.outerHTML;
            },
            resetData(){
                this.resetAllData = true;
                this.page = 1;
                this.results = [];
                this.categories = [];
                this.years = [];
                this.loading = true;

                EventBus.fire(EventNames.REMOVE_ALL_ACTIVE_CATEGORIES);
                EventBus.fire(EventNames.REMOVE_ALL_ACTIVE_YEARS);
                EventBus.fire(EventNames.REMOVE_ALL_ACTIVE_TITLES);

                this.getResults();
            },
            refreshData(){
                if (!this.isGraphicsTab()) {
                    return;
                }
                this.page = 1;
                this.results = [];
                this.loading = true;
                this.getResults();
            },
            getAccessLevel(sku, contentAccessLevelId) {
                if (this.isPremiumSubscribed(sku)) {
                    return 'premiumActive';
                }

                if (this.isBasicAccessBook(contentAccessLevelId)) {
                    return 'basic';
                }

                return 'premium';
            },
            loadPlaceholderImage(e) {
                e.target.src = this.asset(require('../../../images/graphics_image_missing.png'));
            },
            openSubscribeToPremiumModal(documentId){
                this.premiumRequiredDialog = false;
                EventBus.fire('show-premium-subscribe-dialog', {
                    getTitle: true,
                    documentId: documentId
                });
                this.logSearchClick(this.actionTypeMessage.subscribeToPremiumClick);
            },
            handleRightClick(event, sku) {
                if (!this.isPremiumSubscribed(sku)) {
                    event.preventDefault();
                }
            },
            isGraphicsTab() {
                return this.searchTab === this.$getConst('graphicsSearchType');
            },
            isGraphicsSearchUrl() {
                return window.location.href.includes('search/graphics');
            },
            async logSearchClick(actionTaken) {
                const accessLevel = this.getAccessLevel(
                    this.graphicDetails.document_sku,
                    this.graphicDetails.content_access_level_id
                );
                const logRequest = {
                    searchTermId: this.searchTermId,
                    documentId: this.graphicDetails.document_id,
                    contentId: this.graphicDetails.content_id,
                    sectionTitle: this.graphicDetails.section_title,
                    accessLevel: this.accessLevelMsg[accessLevel],
                    action: actionTaken
                }
                await GraphicsApi.logGraphicSearchClick(logRequest);
            },
            redirectToLearnMore(){
                this.logSearchClick(this.actionTypeMessage.learnMoreBtnClick).then(()=>{
                    this.goToRoute('premium_features');
                })
            }
        },
        created() {
            EventBus.listen(EventNames.INIT_GRAPHICS_SEARCH, () => {
                this.resetAllData = true;
                const url = new URL(window.location.href);
                const params = new URLSearchParams(url.search);

                this.search  = params.get('searchTermAny') || '';
                this.defaultSearch = this.search;

                let categories = [];
                let years = [];
                let titles = [];

                if(this.isGraphicsSearchUrl()) {
                    categories = params.getAll('categories');
                    years = params.getAll('years');
                    titles = params.getAll('titles');
                }

                if (categories && categories.length > 0) {
                    this.$store.commit('setTabbedCategories', {
                        tab: this.$getConst('graphicsSearchType'),
                        categories: categories
                    });
                }
                if (years && years.length > 0) {
                    this.$store.commit('setTabbedYears', {
                        tab: this.$getConst('graphicsSearchType'),
                        years: years
                    });
                }
                if (titles && titles.length > 0) {
                    titles = titles.map(title => parseInt(title, 10));
                    this.$store.commit('setTabbedTitleIds', {
                        tab: this.$getConst('graphicsSearchType'),
                        titles: titles
                    });
                }

                this.getResults();
            });
            EventBus.listen(EventNames.UPDATE_GRAPHICS_SEARCH, (data) => {
                if (this.search !== data) {
                    this.search = data;
                    this.refreshData();
                }
            });
            EventBus.listen(EventNames.RESET_GRAPHICS_SEARCH, () => {
                this.resetData();
            });
        },
        mounted() {
            document.addEventListener("keydown", (e) => {
                if ('Escape' === e.key) {
                    e.preventDefault();
                    this.dialog = false;
                    this.premiumRequiredDialog = false;
                    this.filtersDialog = false;
                }
            });
        }
    }
</script>

<style scoped>
.view-graphic-modal__section {
    display: flex;
    flex-direction: column;
    height: 60vh;
}
.view-graphic-modal__content-body ::v-deep(span.section_number) {
    padding-right: 4px !important;
}
.section-border {
    border: 1px solid #0000001F !important;
}
.view-graphic-modal__content-body ::v-deep(h1.chapter span.label) {
    padding-right: 4px;
}
.view-graphic-model__image {
    flex: 0 0 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.view-graphic-modal__content {
    flex: 1;
}
.view-graphic-modal__content-body {
    position: relative;
    overflow: hidden;
    max-height: 13vh;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.img-block-height {
    height: 160px;
    overflow: hidden;
}

</style>