var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      class: { "mx-4": _vm.isMobilePort },
      attrs: { "lazy-validation": "", id: "registration_form", method: "post" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.register.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.csrfToken,
            expression: "csrfToken",
          },
        ],
        attrs: { type: "hidden", name: "csrfToken" },
        domProps: { value: _vm.csrfToken },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.csrfToken = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      !_vm.isEnterprise
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", { staticClass: "primary--text mb-0" }, [
                  _c("strong", [_vm._v("Note:")]),
                  _vm._v(" All fields are required.\n            "),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-text-field",
                {
                  staticClass: "first-name",
                  class: {
                    "pr-2": _vm.isMediumAndUp,
                    "outline-color": !_vm.firstNameHasError,
                  },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    name: "firstName",
                    readonly: _vm.isEnterprise,
                    label: "First Name",
                    error: _vm.firstNameHasError,
                    rules: [_vm.rules.required],
                    id: "firstName",
                    "aria-describedby": "firstNameError",
                  },
                  on: { blur: _vm.checkError },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                },
                [
                  _vm.firstNameHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.firstNameHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "firstNameError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: First name is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-text-field",
                {
                  staticClass: "last-name",
                  class: {
                    "pl-2": _vm.isMediumAndUp,
                    "outline-color": !_vm.lastNameHasError,
                  },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "lastName",
                    name: "lastName",
                    rules: [(v) => !!v || "Last name is required"],
                    error: _vm.lastNameHasError,
                    readonly: _vm.isEnterprise,
                    label: "Last Name",
                    "aria-describedby": "lastNameError",
                    required: "",
                  },
                  on: { blur: _vm.checkError },
                  model: {
                    value: _vm.lastName,
                    callback: function ($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName",
                  },
                },
                [
                  _vm.lastNameHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.lastNameHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1 pl-2",
                      attrs: { id: "lastNameError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Last name is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-text-field",
                {
                  class: { "outline-color": !_vm.securityQuestionHasError },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "securityQuestion",
                    name: "securityQuestion",
                    error: _vm.securityQuestionHasError,
                    rules: [_vm.rules.required],
                    label: "Security Question",
                    "aria-describedby": "securityQuestionError",
                    required: "",
                  },
                  on: { blur: _vm.checkError },
                  model: {
                    value: _vm.securityQuestion,
                    callback: function ($$v) {
                      _vm.securityQuestion = $$v
                    },
                    expression: "securityQuestion",
                  },
                },
                [
                  _vm.securityQuestionHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.securityQuestionHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "securityQuestionError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Security Question is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-text-field",
                {
                  class: { "outline-color": !_vm.securityAnswerHasError },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "securityAnswer",
                    name: "securityAnswer",
                    error: _vm.securityAnswerHasError,
                    rules: [_vm.rules.required],
                    label: "Security Answer",
                    "aria-describedby": "securityAnswerError",
                    required: "",
                  },
                  on: { blur: _vm.checkError },
                  model: {
                    value: _vm.securityAnswer,
                    callback: function ($$v) {
                      _vm.securityAnswer = $$v
                    },
                    expression: "securityAnswer",
                  },
                },
                [
                  _vm.securityAnswerHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.securityAnswerHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "securityAnswerError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Security Answer is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.userExists
        ? _c(
            "v-alert",
            {
              staticClass: "mb-0 alert-box",
              attrs: {
                dense: "",
                outlined: "",
                dismissible: "",
                width: _vm.isMobilePort ? "100%" : "70%",
                color: "black",
              },
            },
            [
              _vm._v("\n        The email has already been registered. "),
              _c("a", { attrs: { href: _vm.getRoute("security_login") } }, [
                _c("b", [_vm._v("Please sign in.")]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "mb-0 alert-box",
          attrs: {
            dense: "",
            outlined: "",
            dismissible: "",
            width: _vm.isMobilePort ? "100%" : "75%",
            color: "black",
          },
          model: {
            value: _vm.invalidEmail,
            callback: function ($$v) {
              _vm.invalidEmail = $$v
            },
            expression: "invalidEmail",
          },
        },
        [
          _vm._v(
            "\n        The email looks invalid. Please double check all characters.\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-text-field",
                {
                  class: { "outline-color": !_vm.emailAddressHasError },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "emailAddress",
                    name: "email",
                    error: _vm.emailAddressHasError,
                    rules: [_vm.rules.required, _vm.validEmail],
                    readonly: _vm.isEnterprise,
                    label: "Email Address",
                    required: "",
                    "aria-describedby": "emailIdError",
                  },
                  on: { blur: _vm.handleEmail },
                  model: {
                    value: _vm.emailAddress,
                    callback: function ($$v) {
                      _vm.emailAddress = $$v
                    },
                    expression: "emailAddress",
                  },
                },
                [
                  _vm.emailAddressHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isEnterprise
                ? _c("span", { staticClass: "caption ml-1" }, [
                    _vm._v(
                      "\n                Email is based on your Enterprise invitation.\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.emailAddressHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "emailIdError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Email is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "mb-0 alert-box",
          attrs: {
            dense: "",
            outlined: "",
            dismissible: "",
            width: _vm.isMobilePort ? "100%" : "75%",
            color: "black",
          },
          model: {
            value: _vm.passwordDidNotMatchRequirement,
            callback: function ($$v) {
              _vm.passwordDidNotMatchRequirement = $$v
            },
            expression: "passwordDidNotMatchRequirement",
          },
        },
        [
          _vm._v(
            "\n        Password length is invalid or contains characters that are not allowed.\n        Please edit it to match the requirements below.\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "mb-0 alert-box",
          attrs: {
            dense: "",
            outlined: "",
            dismissible: "",
            width: _vm.isMobilePort ? "100%" : "45%",
            color: "black",
          },
          model: {
            value: _vm.passwordDidNotMatch,
            callback: function ($$v) {
              _vm.passwordDidNotMatch = $$v
            },
            expression: "passwordDidNotMatch",
          },
        },
        [_vm._v("\n        Passwords don't match.\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-text-field",
                {
                  staticClass: "pass",
                  class: {
                    "pr-2": _vm.isMediumAndUp,
                    "outline-color":
                      !_vm.passwordHasError &&
                      !_vm.passwordDidNotMatchRequirement,
                  },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "password",
                    name: "password",
                    error:
                      _vm.passwordHasError ||
                      _vm.passwordDidNotMatchRequirement,
                    rules: [_vm.rules.required, _vm.validPassword],
                    maxlength: "50",
                    autocomplete: "new-password",
                    type: "password",
                    label: "Password",
                    "aria-describedby": "passwordError",
                    required: "",
                  },
                  on: { blur: _vm.checkError },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                },
                [
                  _vm.passwordHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.passwordHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "passwordError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Password is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-text-field",
                {
                  staticClass: "confirm-pass",
                  class: {
                    "pr-2": _vm.isMediumAndUp,
                    "outline-color": !_vm.confirmPasswordHasError,
                  },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    name: "confirmPassword",
                    error:
                      _vm.confirmPasswordHasError || _vm.passwordDidNotMatch,
                    rules: [_vm.rules.required, _vm.validateConfirmPassword],
                    maxlength: "50",
                    autocomplete: "off",
                    id: "confirmPassword",
                    type: "password",
                    label: "Confirm Password",
                    "aria-describedby": "confirmPasswordError",
                    required: "",
                  },
                  on: { blur: _vm.checkError },
                  model: {
                    value: _vm.confirmPassword,
                    callback: function ($$v) {
                      _vm.confirmPassword = $$v
                    },
                    expression: "confirmPassword",
                  },
                },
                [
                  _vm.confirmPasswordHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.confirmPasswordHasError
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "confirmPasswordError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Password confirmation is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "pt-0" }, [
            _c(
              "p",
              {
                class: this.isEnterprise
                  ? "caption"
                  : "caption mb-0 primary--text",
              },
              [
                _vm._v("\n                Password must be "),
                _c("strong", [_vm._v("min.")]),
                _vm._v(" 10 and "),
                _c("strong", [_vm._v("max.")]),
                _vm._v(
                  " 20 characters with at least one number, one uppercase character and one lowercase character. (Special characters are allowed, but are not mandatory.)\n            "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "p",
                {
                  staticClass: "mb-2 body-1",
                  class: _vm.isEnterprise ? "font-weight-bold" : "",
                },
                [_vm._v("Spam Protection")]
              ),
              _vm._v(" "),
              _c(
                "v-text-field",
                {
                  staticClass: "spam",
                  class: {
                    "outline-color":
                      !_vm.spamProtectionHasError && !_vm.errors.captcha,
                  },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "spamProtection",
                    name: "spamProtection",
                    rules: _vm.captchaRules,
                    label: "What is " + _vm.captcha + "?",
                    error: !!_vm.errors.captcha,
                    "aria-describedby": "spamProtectionError",
                    required: "",
                  },
                  on: { keyup: _vm.checkError, blur: _vm.checkError },
                  model: {
                    value: _vm.spamProtection,
                    callback: function ($$v) {
                      _vm.spamProtection = $$v
                    },
                    expression: "spamProtection",
                  },
                },
                [
                  _vm.spamProtectionHasError || _vm.errors.captcha
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.spamProtectionHasError || _vm.errors.captcha
                ? _c(
                    "div",
                    {
                      staticClass: "error-message my-1 ml-1",
                      attrs: { id: "spamProtectionError" },
                    },
                    [
                      _vm._v(
                        "\n                Error: Spam Protection is required.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.errors.form
        ? _c("v-alert", { attrs: { color: "red", type: "error" } }, [
            _vm._v("\n        " + _vm._s(_vm.errors.form) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.freeTrial && !_vm.isEnterprise
        ? _c(
            "v-alert",
            {
              staticClass: "mb-0 alert-box",
              attrs: {
                dense: "",
                outlined: "",
                dismissible: "",
                width: _vm.isMobilePort ? "100%" : "55%",
                color: "black",
              },
            },
            [
              _vm._v("\n        Are you sure you want to opt out from your "),
              _c("br"),
              _vm._v(" "),
              _c("b", [_vm._v("FREE 14-Day Premium Complete Trial?")]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        {
          attrs: {
            align: _vm.isEnterprise ? "center" : "start",
            justify: _vm.isEnterprise ? "center" : "start",
          },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !_vm.isEnterprise && !_vm.isTrialRegistration
                ? _c("v-checkbox", {
                    staticClass: "mt-0 pt-0",
                    attrs: { outlined: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "p",
                                { staticClass: "caption mb-0 primary--text" },
                                [
                                  _vm._v("\n                        Try "),
                                  _c("b", [_vm._v("Premium Complete")]),
                                  _vm._v(" FREE for 14-days. "),
                                  _c("br"),
                                  _vm._v(
                                    "\n                        No credit card required.\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3197803553
                    ),
                    model: {
                      value: _vm.freeTrial,
                      callback: function ($$v) {
                        _vm.freeTrial = $$v
                      },
                      expression: "freeTrial",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    block: "",
                    type: "submit",
                    color: "primary",
                    disabled: _vm.isError || _vm.isLoading,
                    loading: _vm.isLoading,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.isEnterprise ? "Create Your Account" : "Sign Up"
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isEnterprise
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", { staticClass: "caption mb-0 primary--text" }, [
                  _vm._v(
                    "\n                By signing up for the free trial of ICC Digital Codes Premium, you agree to receive email updates from ICC Digital Codes and are able to unsubscribe at any time.\n            "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "trial" },
        domProps: { value: _vm.freeTrial || _vm.isTrialRegistration ? 1 : 0 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }