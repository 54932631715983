<template>
    <v-row
        class="hero-container"
        :class="{
            'py-16': !isMobilePort,
            'pb-8': isMobilePort
        }"
        align="center"
        justify="center"
        :style="showBorder ? 'border-bottom: 3px solid '+$vuetify.theme.themes.light.accent2 : ''">
        <v-col
            :class="{'text-center': isMobilePort}"
            cols="12"
            sm="6"
            :order="!isMobilePort ? 1 : 2">
            <h1
                class="font-weight-light mb-4 oxygen"
                v-html="title">
            </h1>
            <h2
                v-if="subtitle"
                class="font-weight-regular"
                v-html="subtitle">
            </h2>
            <v-btn
                rounded
                v-if="button"
                class="mt-4 white--text"
                dark
                x-large
                :color="button.color ? button.color : 'accent2'"
                :target="button.target ? button.target : '_self'"
                :href="button.link"
                @click.stop="logGtm()">
                {{button.text}}
            </v-btn>
        </v-col>
        <v-col :order="!isMobilePort ? 2 : 1">

            <slot name="right">

                <!-- default content if no right slot is defined --->

                <img
                        :class="image.class"
                        :width="image.width"
                        :src="asset(require('../../images/'+image.src), 'default')"/>
                <p
                        v-if="image.caption"
                        class="mb-0 caption"
                        v-html="image.caption">
                </p>
            </slot>

        </v-col>
    </v-row>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';

    export default {
        props: {
            title: {
                required: true,
                type: String
            },
            subtitle: {
                required: false,
                type: String
            },
            button: {
                required: false,
                type: Object
            },
            image: {
                required: false,
                type: Object
            },
            showBorder: {
                required: false,
                type: Boolean,
                default: true
            },
            gtm: {
                required: false,
                type: Object
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            logGtm() {
                if (this.gtm) {
                    this.gtmEvent(
                        this.gtm.category,
                        this.gtm.label,
                        this.gtm.action
                    )
                }
            }
        }
    }
</script>

<style
    lang="scss"
    scoped>
    .hero-container {
        img {
            max-width: 100%;
        }
        .oxygen {
            font-family: Oxygen;
        }
        @media (min-width: 641px) {
            h1 {
                font-size: 54px;
                line-height: 68px;
            }
            h2 {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
</style>
