<template>
    <div>
        <premium-tool-header
            ref="premiumToolHeader"
            title="Compliance Calculators"
            sub-title="In-app computations to streamline your workflow. ">
            <template v-slot:avatar>
                <v-icon
                    class="accent2Light"
                    color="accent2"
                    size="36">
                    mdi-calculator-variant-outline
                </v-icon>
            </template>
        </premium-tool-header>
        <v-divider>
        </v-divider>
        <div class="grey lighten-5">
            <template v-if="!iframe">
                <v-container class="content-container pt-0 pb-12">
                    <account-access-expand type="calculatorCodeCompliance">
                        <template v-slot:subText>
                            Powered by ClearCalcs, get access to available compliance calculators on Digital Codes
                        </template>
                    </account-access-expand>
                    <v-row>
                        <v-col>
                            <h1 class="fs-24 oxygen font-weight-bold mt-6">
                                Get started
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row v-if="loading || !calculators.length">
                        <v-col>
                            <v-card
                                class="mb-6"
                                outlined>
                                <v-card-text>
                                    <loading-linear v-if="loading">
                                    </loading-linear>
                                    <p
                                        v-else
                                        class="mb-0 text-center">
                                        No calculators were found.  Please check back later.
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row
                        v-else
                        justify="center">
                        <v-col
                            class="cp"
                            v-for="(item, key) in calculators"
                            :key="key"
                            @click="calculatorClickAction(item)"
                            cols="12"
                            sm="4">
                            <v-card class="fill-height d-flex flex-column">
                                <div class="text-right pa-1">
                                    <access-icon
                                        :accessLevel="hasPremium ? 'premiumActive' : 'premium'">
                                    </access-icon>
                                </div>
                                <div class="text-center">
                                    <img
                                        height="200px"
                                        :src="asset(item.image_url ? item.image_url : require('../../images/calculators/calculator_placeholder.svg'))">
                                </div>
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col>
                                            <h3 class="fs-20">
                                                {{item.title}}
                                            </h3>
                                            <p class="mt-3 mb-0">
                                                {{item.description}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-spacer>
                                </v-spacer>
                                <v-card-actions class="pa-3 pt-1">
                                    <v-btn
                                        color="primary"
                                        :disabled="!hasPremium">
                                        <v-icon
                                            left
                                            dark>
                                            mdi-calculator-variant-outline
                                        </v-icon>
                                        Open Calculator
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="darkBlue py-2">
                    <v-container class="content-container white--text">
                        <v-row align="center">
                            <v-col>
                                <v-row
                                    dense
                                    align="center">
                                    <v-col cols="auto">
                                        <img
                                            class="mt-2"
                                            :src="asset(require('../../images/calculators/clearcals_icon.webp'))"
                                            alt="ClearCalcs Logo"
                                            title="ClearCalcs Logo">
                                    </v-col>
                                    <v-col>
                                        <h2 class="fs-24 oxygen font-weight-bold">
                                            Powered by ClearCalcs
                                        </h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <p class="fs-20 font-weight-medium mb-2">
                                            Overview of ClearCalcs
                                        </p>
                                        <p class="mb-6">
                                            ClearCalcs streamlines structural engineering with cloud structural design and analysis software to help engineers and designers do their best work with confidence.
                                        </p>
                                        <p class="fs-20 font-weight-medium mb-2">
                                            An ICC/ClearCalcs Collaboration
                                        </p>
                                        <p class="mb-6">
                                            ICC and ClearCalcs are teaming up to provide high quality compliance calculators right in Digital Codes. This is a pilot program that we are very excited to make available and expand on.
                                        </p>
                                        <v-btn
                                            :href="clearCalcUrl"
                                            target="_blank"
                                            :block="isMobilePort"
                                            :large="isMobilePort">
                                            Learn more at ClearCalcs
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="!isMobilePort">
                                <img
                                    :src="asset(require('../../images/calculators/calculator.webp'))"
                                    alt="ClearCalcs Calculator"
                                    title="ClearCalcs Calculator">
                            </v-col>
                        </v-row>
                        <v-row class="mt-4">
                            <v-col>
                                <h2
                                    class="fs-20 oxygen font-weight-bold"
                                    :class="{'text-center': !isMobilePort}">
                                    More calculations from ClearCalcs
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(item, key) in clearCalcs"
                                :key="key"
                                cols="12"
                                sm="3">
                                <h3 class="fs-20 font-weight-medium mb-6">
                                    {{item.name}}
                                </h3>
                                <p
                                    v-for="(item, key) in item.calcs"
                                    :key="key"
                                    class="mb-1">
                                    {{item}}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <v-container class="content-container py-12">
                    <v-row>
                        <v-col>
                            <f-a-qs :faqs="faqs">
                            </f-a-qs>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <a @click="getPremiumCompleteBook()">
                                <img
                                    class="mt-6"
                                    :src="asset(require('../../images/banners/unlock_premium_complete.webp'))"
                                    alt="Unlock Premium Complete"
                                    title="Unlock Premium Complete">
                            </a>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <v-container
                class="content-container"
                v-else>
                <v-row>
                    <v-col>
                        <a
                            class="fs-16"
                            @click="iframe = null">
                            <v-icon
                                color="primary"
                                class="mt-n1"
                                left>
                                mdi-arrow-left
                            </v-icon>
                            Back to Get Started
                        </a>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card outlined>
                            <v-card-title>
                                {{iframe.title}}
                            </v-card-title>
                            <v-divider>
                            </v-divider>
                            <v-card-text class="pa-0">
                                <iframe
                                    :height="`${iframe.iframe_height ? iframe.iframe_height : 600}px`"
                                    width="100%"
                                    style="border: 0;"
                                    :src="iframe.iframe_url">
                                </iframe>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
    import PremiumToolHeader from "../../components/headers/PremiumToolHeader.vue";
    import AccountAccessExpand from "../../components/expands/AccountAccessExpand.vue";
    import LoadingLinear from "../../components/loaders/LoadingLinear.vue";
    import FAQs from "../../components/expands/FAQs.vue";
    import AccessIcon from "../../components/icons/AccessIcon.vue";
    import {ComplianceCalculatorsApi} from "../../api/ComplianceCalculators";
    import Mobile from "../../components/inc/mixins/mobile";
    import Constants from "../../constants";

    export default {
        name: 'ComplianceCalculators',
        components: {FAQs, LoadingLinear, AccountAccessExpand, PremiumToolHeader, AccessIcon},
        mixins: [Mobile, Constants],
        data() {
            return {
                loading: true,
                calculators: [],
                iframe: null,
                clearCalcUrl: Constants.links.clearCalcUrl,
                clearCalcs: [
                    {
                        name: 'Analysis and Loads',
                        calcs: [
                            'Portal Frame Analysis Wizards',
                            'Truss Analysis Wizard with 2D FEA',
                            'Custom Thin-Walled Cross Section Analysis'
                        ]
                    },
                    {
                        name: 'Wood',
                        calcs: [
                            'Wood Beam ASD or LFRD',
                            'Flitch Beam ASD or LFRD',
                            'Wood Column ASD or LFRD',
                            'Wood Roof Tie ASD or LFRD'
                        ]
                    },
                    {
                        name: 'Steel and CFS',
                        calcs: [
                            'Steel Beam ASD or LFRD',
                            'Steel Design Only-Member',
                            'Steel Column ASD or LFRD',
                            'Cold-Formed Steel Beam'
                        ]
                    },
                    {
                        name: 'Concrete and Masonry',
                        calcs: [
                            'Concrete Beam',
                            'Concrete Column',
                            'Masonry Gravity Wall ASD'
                        ]
                    }
                ],
                faqs: [
                    {
                        title: 'What compliance calculators are available on Digital Codes?',
                        description: '<p>Digital Codes Premium has partnering with ClearCalcs to deliver an expanding set of compliance calculators to simplify workflow needs.  All available calculators are listed on the Compliance Calculator feature page with a new calculator being released to the Digital Codes Premium platform each quarter.</p>'
                    },
                    {
                        title: 'Are there additional compliance calculators available for usage?',
                        description: '<p>There are additional structural calculators and features available via subscription offerings through <a href="' + Constants.links.clearCalcUrl + '" target="_blank">Clear Calcs</a>. Users can browse the full set of available calculators <a href="https://www.clearcalcs.com/calculations?codes=us" target="_blank">here</a>.</p>'
                    },
                    {
                        title: 'What level of Premium subscription is required to access the compliance calculators?',
                        description: '<p>All levels of Digital Codes Premium, including Premium Complete Trial users, have access to the three calculators included on Digital Codes platform.</p>'
                    },
                    {
                        title: 'Where can I find the compliance calculators within Digital Codes Premium?',
                        description: '<p>The included structural compliance calculators can be accessed from the compliance calcs landing page as well as via Premium Code Insights across the related titles and sections.</p>'
                    },
                    {
                        title: 'Who is ClearCalcs?',
                        description: '<p>ClearCalcs is a cloud-based structural design and analysis platform, which helps businesses in the engineering sector create and verify design and computations for a range of elements such as beams, columns, retaining walls, concrete footings, and more.  Learn more about ClearCalcs here (clearcalcs.com).</p>'
                    }
                ]
            };
        },
        methods: {
            async getCalculators() {
                this.loading = true;
                const results = await ComplianceCalculatorsApi.getCalculators();
                this.calculators = results.data;
                this.loading = false;
            },
            async calculatorClickAction(calculator) {
                this.iframe = this.hasPremium ? calculator : null;

                if (this.hasPremium) {
                  const postData = { calculatorId: calculator.id };

                  try {
                    const response = await ComplianceCalculatorsApi.logCalculatorClick(postData);

                    if (!response.data.success) {
                        // Handle the endpoint error
                        this.handleError("Unable to log the calculator click");
                    }
                  } catch (error) {
                        // Handle network or other errors
                        this.handleError("An error occurred while logging the calculator click");
                    }
                }
            },
            handleError(errorMessage) {
              // Can be replaced as required
              console.error(errorMessage);
            }
        },
        mounted() {
            this.getCalculators();
        }
    }
</script>
