<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        id="registration_form"
        method="post"
        @submit.prevent="register"
        :class="{ 'mx-4': isMobilePort }">
        <input type="hidden" v-model="csrfToken" name="csrfToken">
        <v-row v-if="!isEnterprise">
            <v-col>
                <p class="primary--text mb-0">
                    <strong>Note:</strong> All fields are required.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    outlined
                    hide-details
                    name="firstName"
                    v-model="firstName"
                    :readonly="isEnterprise"
                    label="First Name"
                    :error="firstNameHasError"
                    @blur="checkError"
                    :rules="[rules.required]"
                    id="firstName"
                    class="first-name"
                    aria-describedby="firstNameError"
                    :class="{
                        'pr-2': isMediumAndUp,
                        'outline-color': !firstNameHasError,
                    }">
                    <v-icon
                    slot="append"
                    color="red"
                    v-if="firstNameHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="firstNameHasError" class="error-message my-1 ml-1" id="firstNameError">
                    Error: First name is required.
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    outlined
                    hide-details
                    id="lastName"
                    name="lastName"
                    :rules="[v => !!v || 'Last name is required']"
                    @blur="checkError"
                    v-model="lastName"
                    :error="lastNameHasError"
                    :readonly="isEnterprise"
                    label="Last Name"
                    class="last-name"
                    aria-describedby="lastNameError"
                    :class="{
                        'pl-2': isMediumAndUp,
                        'outline-color': !lastNameHasError
                    }"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="lastNameHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="lastNameHasError" class="error-message my-1 ml-1 pl-2" id="lastNameError">
                    Error: Last name is required.
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    outlined
                    hide-details
                    id="securityQuestion"
                    name="securityQuestion"
                    :error="securityQuestionHasError"
                    :rules="[rules.required]"
                    v-model="securityQuestion"
                    label="Security Question"
                    @blur="checkError"
                    aria-describedby="securityQuestionError"
                    :class="{'outline-color': !securityQuestionHasError}"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="securityQuestionHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="securityQuestionHasError" class="error-message my-1 ml-1" id="securityQuestionError">
                    Error: Security Question is required.
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    outlined
                    hide-details
                    id="securityAnswer"
                    name="securityAnswer"
                    :error="securityAnswerHasError"
                    :rules="[rules.required]"
                    v-model="securityAnswer"
                    label="Security Answer"
                    @blur="checkError"
                    aria-describedby="securityAnswerError"
                    :class="{'outline-color': !securityAnswerHasError}"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="securityAnswerHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="securityAnswerHasError" class="error-message my-1 ml-1" id="securityAnswerError">
                    Error: Security Answer is required.
                </div>
            </v-col>
        </v-row>
        <v-alert
            dense
            outlined
            dismissible
            :width="isMobilePort ? '100%' : '70%'"
            color="black"
            class="mb-0 alert-box"
            v-if="userExists"
        >
            The email has already been registered. <a :href="getRoute('security_login')"><b>Please sign in.</b></a>
        </v-alert>
        <v-alert
            dense
            outlined
            dismissible
            :width="isMobilePort ? '100%' : '75%'"
            color="black"
            class="mb-0 alert-box"
            v-model="invalidEmail"
        >
            The email looks invalid. Please double check all characters.
        </v-alert>
        <v-row>
            <v-col>
                <v-text-field
                    outlined
                    hide-details
                    id="emailAddress"
                    name="email"
                    :error="emailAddressHasError"
                    :rules="[rules.required, validEmail]"
                    v-model="emailAddress"
                    :readonly="isEnterprise"
                    label="Email Address"
                    required
                    @blur="handleEmail"
                    aria-describedby="emailIdError"
                    :class="{'outline-color': !emailAddressHasError}">
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="emailAddressHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <span v-if="isEnterprise" class="caption ml-1">
                    Email is based on your Enterprise invitation.
                </span>
                <div v-if="emailAddressHasError" class="error-message my-1 ml-1" id="emailIdError">
                    Error: Email is required.
                </div>
            </v-col>
        </v-row>
        <v-alert
            dense
            outlined
            dismissible
            :width="isMobilePort ? '100%' : '75%'"
            color="black"
            class="mb-0 alert-box"
            v-model="passwordDidNotMatchRequirement"
        >
            Password length is invalid or contains characters that are not allowed.
            Please edit it to match the requirements below.
        </v-alert>
        <v-alert
            dense
            outlined
            dismissible
            :width="isMobilePort ? '100%' : '45%'"
            color="black"
            class="mb-0 alert-box"
            v-model="passwordDidNotMatch"
        >
            Passwords don't match.
        </v-alert>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    outlined
                    hide-details
                    id="password"
                    name="password"
                    :error="passwordHasError || passwordDidNotMatchRequirement"
                    :rules="[rules.required, validPassword]"
                    maxlength="50"
                    autocomplete="new-password"
                    :type="'password'"
                    v-model="password"
                    label="Password"
                    @blur="checkError"
                    class="pass"
                    aria-describedby="passwordError"
                    :class="{
                        'pr-2': isMediumAndUp,
                        'outline-color': !passwordHasError && !passwordDidNotMatchRequirement
                    }"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="passwordHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="passwordHasError" class="error-message my-1 ml-1" id="passwordError">
                    Error: Password is required.
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    outlined
                    hide-details
                    name="confirmPassword"
                    :error="confirmPasswordHasError || passwordDidNotMatch"
                    :rules="[rules.required, validateConfirmPassword]"
                    maxlength="50"
                    autocomplete="off"
                    id="confirmPassword"
                    :type="'password'"
                    v-model="confirmPassword"
                    label="Confirm Password"
                    @blur="checkError"
                    class="confirm-pass"
                    aria-describedby="confirmPasswordError"
                    :class="{
                        'pr-2': isMediumAndUp,
                        'outline-color': !confirmPasswordHasError
                    }"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="confirmPasswordHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="confirmPasswordHasError" class="error-message my-1 ml-1" id="confirmPasswordError">
                    Error: Password confirmation is required.
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <p :class="this.isEnterprise ? 'caption' : 'caption mb-0 primary--text'">
                    Password must be <strong>min.</strong> 10 and <strong>max.</strong> 20 characters with at least one number, one uppercase character and one lowercase character. (Special characters are allowed, but are not mandatory.)
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <p class="mb-2 body-1" :class="isEnterprise ? 'font-weight-bold' : ''">Spam Protection</p>
                <v-text-field
                    outlined
                    hide-details
                    id="spamProtection"
                    name="spamProtection"
                    :rules="captchaRules"
                    v-model="spamProtection"
                    :label="'What is '+captcha+'?'"
                    :error="!!errors.captcha"
                    @keyup="checkError"
                    @blur="checkError"
                    class="spam"
                    aria-describedby="spamProtectionError"
                    :class="{
                        'outline-color': !spamProtectionHasError && !errors.captcha
                    }"
                    required>

                    <v-icon
                        slot="append"
                        color="red"
                        v-if="spamProtectionHasError || errors.captcha"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
                <div v-if="spamProtectionHasError || errors.captcha" class="error-message my-1 ml-1" id="spamProtectionError">
                    Error: Spam Protection is required.
                </div>
            </v-col>
        </v-row>
        <v-alert
                v-if="errors.form"
                color="red"
                type="error">
            {{ errors.form }}
        </v-alert>
        <v-alert
            dense
            outlined
            dismissible
            :width="isMobilePort ? '100%' : '55%'"
            color="black"
            class="mb-0 alert-box"
            v-if="!freeTrial && !isEnterprise"
        >
            Are you sure you want to opt out from your <br>
            <b>FREE 14-Day Premium Complete Trial?</b>
        </v-alert>
        <v-row :align="isEnterprise ? 'center' : 'start'" :justify="isEnterprise ? 'center' : 'start'">
            <v-col
                cols="12"
                sm="6">
                <v-checkbox
                    v-if="!isEnterprise && (!isTrialRegistration)"
                    v-model="freeTrial"
                    class="mt-0 pt-0"
                    outlined>
                    <template v-slot:label>
                        <p class="caption mb-0 primary--text">
                            Try <b>Premium Complete</b> FREE for 14-days. <br>
                            No credit card required.
                        </p>
                    </template>
                </v-checkbox>
                <v-btn
                    large
                    block
                    type="submit"
                    color="primary"
                    :disabled="isError || isLoading"
                    :loading="isLoading">
                    {{this.isEnterprise ? 'Create Your Account' : 'Sign Up'}}
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!isEnterprise">
            <v-col>
                <p class="caption mb-0 primary--text">
                    By signing up for the free trial of ICC Digital Codes Premium, you agree to receive email updates from ICC Digital Codes and are able to unsubscribe at any time.
                </p>
            </v-col>
        </v-row>
        <input type="hidden" name="trial" :value="(freeTrial || isTrialRegistration) ? 1 : 0" />
    </v-form>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';
    import { StringUtil } from "../../util/StringUtil";
    import {AppParameters} from "../../AppParameters";
    import axios from "axios";

    export default {
        data() {
            return {
                captcha: '',
                valid: true,
                firstName: '',
                lastName: '',
                securityQuestion: '',
                securityAnswer: '',
                emailAddress: '',
                spamProtection: '',
                password: '',
                confirmPassword: '',

                isLoading: false,

                form: {
                    captcha: ''
                },

                // errors caught from backend
                errors: {
                    form: null,
                    captcha: null,
                },

                firstNameHasError: false,
                lastNameHasError: false,
                securityQuestionHasError: false,
                securityAnswerHasError: false,
                emailAddressHasError: false,
                invalidEmail: false,
                spamProtectionHasError: false,
                passwordHasError: false,
                confirmPasswordHasError: false,
                passwordDidNotMatch: false,
                passwordDidNotMatchRequirement: false,
                userExists: false,

                isError: false,
                csrfToken: document.getElementById('app').attributes['data-csrf-token'].value,
                isRegistrationClosed: AppParameters.registration_closed,
                freeTrial: true,
                rules: {
                    required: value => !!value || 'This field is required.'
                },
                captchaRules: [
                    value => !!value || 'Must solve captcha',
                    v => !this.errors.captcha || this.errors.captcha
                ]
            }
        },
        props: {
            isTrialRegistration: {
                required: false,
                type: Boolean,
                default: false
            },
            isEnterprise: {
                required: false,
                type: Boolean,
                default: false
            },
            enterpriseData: {
                required: false,
                type: Object
            }
        },
        mixins: [Mobile],
        methods: {
            resetErrors() {
                for (let key in this.errors) {
                    this.errors[key] = null;
                }
            },
            hasAnyError(){
                if (this.firstNameHasError || this.lastNameHasError || this.securityQuestionHasError
                    ||
                    this.securityAnswerHasError || this.emailAddressHasError || this.spamProtectionHasError
                    ||
                    this.passwordHasError || this.confirmPasswordHasError || this.passwordDidNotMatch
                    ||
                    this.passwordDidNotMatchRequirement || this.userExists ){
                        this.isError = true;
                } else {
                    this.isError = false;
                }

                return this.isError;
            },
            validateUsernamePasswordMatch(email, password) {
                let usernameValue = email.split('@')[0].split('.').join("");
                let result = StringUtil.findIntersection(usernameValue.toLowerCase(),password.toLowerCase());
                return (result?.length > 3);
            },
            checkError(element){
                this.resetErrors();

                let errorName = element.target.name + 'HasError';
                let data = this;
                if(element.target._value === ''){
                    data[errorName] = true;
                } else {
                    data[errorName] = false;
                }
                if ('password' === element.target.name && element.target._value !== '') {
                    if (!this.validPassword(this.password) || this.validateUsernamePasswordMatch(this.emailAddress, this.password)) {
                        this.passwordDidNotMatchRequirement = true;
                    } else {
                        this.passwordDidNotMatchRequirement = false;
                    }
                }
                if ('confirmPassword' === element.target.name && element.target._value !== '') {
                    if (this.password !== this.confirmPassword) {
                        this.passwordDidNotMatch = true;
                        this.confirmPasswordHasError = true;
                    } else {
                        this.passwordDidNotMatch = false;
                        this.confirmPasswordHasError = false;
                    }
                }
                this.hasAnyError();
            },
            getFormCaptcha() {
                this.$http.get(Routing.generate('get_captcha')).then(
                    response => {
                        if (response.data) {
                            this.captcha = response.data;
                        }
                    }
                );
            },
            handleEmail() {
                if (!_.isEmpty(this.emailAddress)) {
                    if (this.validEmail(this.emailAddress)) {
                        this.invalidEmail = false;
                        this.$http.get(Routing.generate('check_for_valid_email', {'email': this.emailAddress})).then(
                            response => {
                                if (response.data) {
                                    if (response.data.status) {
                                        this.userExists = true;
                                        this.emailAddressHasError = true;
                                    } else {
                                        this.userExists = false;
                                        this.emailAddressHasError = false;
                                    }
                                } else {
                                    this.emailAddressHasError = false;
                                }
                                this.hasAnyError();
                            }
                        );
                    } else {
                        this.invalidEmail = true;
                    }
                } else {
                    this.emailAddressHasError = true;
                }
            },
            validateFields(){
                this.firstNameHasError  = !this.firstName ? true : false;
                this.lastNameHasError  = !this.lastName ? true : false;
                this.securityQuestionHasError = !this.securityQuestion ? true : false;
                this.securityAnswerHasError = !this.securityAnswer ? true : false;
                this.spamProtectionHasError = !this.spamProtection ? true : false;
                this.passwordHasError = !this.password ? true : false;
                this.confirmPasswordHasError = !this.confirmPassword ? true : false;
                this.emailAddressHasError = !this.emailAddress ? true : false;

                if (this.userExists) {
                    this.$ga.event({
                        hitType: 'event',
                        eventCategory: 'trialSignUpError',
                        eventAction: 'click',
                        eventLabel: 'User account already exists'
                    })
                }

                if (this.password !== this.confirmPassword) {
                    this.passwordDidNotMatch = true;
                }

                if(this.password){
                    if (!this.validPassword(this.password) || this.validateUsernamePasswordMatch(this.emailAddress, this.password)) {
                        this.passwordDidNotMatchRequirement = true;
                    }
                }
            },
            async register(e) {
                e.preventDefault();
                this.resetErrors();
                this.validateFields();
                if (this.$refs.form.validate() && !this.hasAnyError()) {
                    await this.pathmonkConversionPixel()
                    this.submitForm();
                } else {
                    this.isError = true;
                }
            },
            validEmail:function(email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let isValidEmail = re.test(email);

                if (email !== '' && !isValidEmail) {
                    this.emailAddressHasError = true;
                }

                return isValidEmail;
            },
            validPassword:function(password) {
                let re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[\w~@#$%^&*+=_`><|/\\,'{}:;!.?\"()\[\]-]{10,20}$/;
                let isValidPass =  re.test(password);

                if (password !== '' && !isValidPass) {
                    this.passwordHasError = true;
                }

                return isValidPass;
            },
            validateConfirmPassword(password) {
                if (password !== '' && this.password !== password) {
                    this.confirmPasswordHasError = true;
                }

                return true;
            },
            validAlphanumerics:function(password) {
                if (password.replace(/[^A-Z0-9]/gi, '').length < 10) {
                    return false;
                }
                return true;
            },
            logRegistrationEvent() {
                this.gtmEvent('Sign Up', 'Free Trial', 'Sign Up');
                if (this.gaEnabled) {
                    this.$ga.event({
                        hitType: 'event',
                        eventCategory: 'trialSignUp',
                        eventAction: 'click',
                        eventLabel: 'New Premium Complete Trial sign up'
                    })
                }
            },
            submitForm() {

                this.resetErrors();
                this.isLoading = true;

                const form = this.$refs.form.$el;
                const formData = new FormData(form);
                const postData = (new URLSearchParams(formData)).toString();

                const registerEndpoint = Routing.generate('register_user');

                axios.post(registerEndpoint, postData).then((response) => {

                    /** @type {{errors: {field: String, error: String}[], next: String}} */
                    const data = response.data;

                    if (data.errors) {

                        data.errors.forEach((err) => {
                            this.errors[err.field] = err.error;
                        });

                    } else if (data.next) {
                        this.logRegistrationEvent();

                        setTimeout(() => {
                            window.location = data.next;
                        }, 500);
                    }

                }).catch(() => {
                    alert('Something went wrong');
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        mounted() {
            if(this.enterpriseData) {
                this.firstName = this.enterpriseData.inviteFirstname;
                this.lastName = this.enterpriseData.inviteLastname;
                this.emailAddress = this.enterpriseData.inviteEmail;
                this.enterprise = this.enterpriseData.inviteEnterprise;
                this.freeTrial = !this.isEnterprise;
            }
            this.getFormCaptcha();
        }
    }
</script>
<style
    lang="scss"
    scoped>
    .alert-box {
        background-color: var(--v-seaFoamGreen-base) !important;
        border: 2px solid red !important;
    }
    .outline-color.v-text-field--outlined >>> fieldset {
        border-color: #0B5940;
    }
</style>
