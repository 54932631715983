<template>
    <div>
        <template v-if="!loading">
            <v-container class="content-container">
                <hero-banner
                    v-if="sectionsData.headerSection"
                    :title="sectionsData.headerSection.title"
                    :subtitle="sectionsData.headerSection.subtitle"
                    :button="sectionsData.headerSection.button"
                    :image="sectionsData.headerSection.image">

                    <template v-slot:right>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/xcMiYiZ3X98?si=zs5UzX7g5nKdM5KC"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </template>

                </hero-banner>
                <exam-feature
                    v-if="1 === persona"
                    :sectionData="sectionsData['featureSection']">
                </exam-feature>
                <exam-feature
                    v-if="sectionsData['featureSection1']"
                    :sectionData="sectionsData['featureSection1']">
                </exam-feature>
                <exam-tips
                    v-if="2 === persona"
                    :sectionData="sectionsData['examTipsSection']">
                </exam-tips>
                <exam-feature
                    v-if="2 === persona"
                    :sectionData="sectionsData['featureSection']">
                </exam-feature>
                <exam-timeline
                    v-if="3 === persona || 4 === persona"
                    :persona="persona">
                </exam-timeline>
                <exams
                    v-if="1 !== persona"
                    :exams="exams"
                    :persona="persona">
                </exams>
                <exam-tips
                    v-if="3 === persona || 4 === persona"
                    :sectionData="sectionsData['examTipsSection']">
                </exam-tips>
                <exam-timeline
                    v-if="2 === persona"
                    :persona="persona">
                </exam-timeline>
            </v-container>
            <exam-footer
                v-if="sectionsData['footerSection']"
                :persona="getPersona()"
                :freeTrialAvailable="freeTrialAvailable"
                :displayData="sectionsData['footerSection']">
            </exam-footer>
        </template>
        <v-row v-else>
            <v-col
                v-if="loading"
                class="text-center my-12">
                <v-progress-circular
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </v-col>
        </v-row>
        <template>
            <v-dialog
                v-model="modal"
                :fullscreen="isMobileAndTablet"
                max-width="500px">
                <v-card>
                    <v-card-title
                        class="primary white--text"
                        :class="{
                            'headline': !isMobilePort,
                            'caption mobile-title': isMobilePort}">
                        <v-row
                            dense
                            align="center">
                            <v-col>
                                {{ 'Digital References Not Found' }}
                            </v-col>
                            <v-col cols="auto">
                                <v-icon
                                    small
                                    class="accent2--text"
                                    @click="modal = false">
                                    close
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-container class="text-center py-12">
                            <p>'The Digital References for this exam could not be found. Please contact the Assessment Center at 
                                '<a href="mailto:customersuccess@iccsafe.org">customersuccess@iccsafe.org</a>' and provide them the exam name and the url below.'</p>
                            <p>{{ url }}</p>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
    import HeroBanner from "../banners/HeroBanner";
    import ExamFeature from "./partials/ExamFeature";
    import ExamTimeline from "./partials/ExamTimeline";
    import ExamTips from "./partials/ExamTips";
    import ExamFooter from "./partials/ExamFooter.vue";
    import Mobile from './../inc/mixins/mobile';
    import PersonaData from './static/PersonaData.js';
    import Exams from "./Exams";
    import {SuperAxios} from "../../api/SuperAxios";

    export default {
        data() {
            return {
                exams: [
                    {
                        examId: null,
                        examTitle: null,
                        referenceSlug: null,
                        references: {
                            sku: null,
                            title: null,
                            slug: null,
                        }
                    }
                ],
                persona: 1,
                freeTrialAvailable: true,
                loading: false,
                sectionsData: {
                    headerSection: null,
                    contentImageSection: {
                        section1: null,
                        section2: null
                    }
                },
                modal: false,
                url: ''
            };
        },
        props: {
            referer: {
                required: true,
                type: String
            },
        },
        components: {
            HeroBanner,
            ExamFeature,
            ExamTimeline,
            ExamTips,
            Exams,
            ExamFooter
        },
        mixins: [
            Mobile
        ],
        computed: {
            userId() {

                if (this.$store.getters.getSwitchUser) {
                    return this.$store.getters.getSwitchUser.id;
                }

                return this.$store.getters.getUser ? this.$store.getters.getUser.id : null;
            }
        },
        watch: {
            userId: {
                handler(value) {

                    if (value) {
                        this.setUserPersona(value);
                    }
                },
                immediate: true
            }
        },
        methods: {
            setUserPersona(id) {
                const apiUrl = Routing.generate('exam_details', {
                    userId: id
                });

                this.loading = true;

                SuperAxios.getOnce(apiUrl).then(response => {
                    this.persona = response.data.persona_type;
                    this.exams = response.data.exams;
                    this.freeTrialAvailable = response.data.free_trial_available;

                    // Todo:  Remove after demo (temp)
                    const pq = (new URL(document.location)).searchParams.get("persona");
                    if (pq) {
                        this.persona = pq;
                    }

                    this.sectionsData = PersonaData[`persona${this.persona}`];
                }).catch(this.handleError).finally(() => {
                    this.loading = false;
                })
            },
            getPersona() {
                return _.toNumber(this.persona);
            }
        },
        beforeMount() {
            this.sectionsData = PersonaData[`persona${this.persona}`];
        },
        mounted() {
            this.modal = this.referer ? true : false;
            this.url = 'URL: ' + window.location.origin + '/exams/preview/' + this.referer;
        }
    }
</script>

<style lang="scss" scoped>
.hero-container iframe {
  width: 100% !important;
}
</style>