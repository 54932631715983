<template>
    <premium-tool-header
        title="Premium Answers"
        sub-title="Real questions answered by our expert team. ">
        <template v-slot:avatar>
            <img
                width="30"
                height="102"
                :src="asset(require('../../images/icons/premium_answers_icon.svg'))"
                alt="FEEDBACK"
                title="Feedback">
        </template>
        <template v-slot:tabs>
            <v-tabs
                v-if="quickAskEnabled"
                v-model="activeTab"
                align-tabs="left"
                class="white">
                <v-tab
                    value="0"
                    @click="goToDatabase"
                    class="font-weight-bold"
                    :class="activeTab === 0 ? 'white--text' : 'grey--darken-1'">
                    Q&A Database
                </v-tab>
                <v-tab
                    value="1"
                    @click="goToQuickConsult"
                    class="font-weight-bold px-4"
                    :class="activeTab === 1 ? 'white--text' : 'grey--darken-1'">
                    Quick Consult
                    <v-chip color="primary" class="ma-1 pa-2" small>NEW</v-chip>
                </v-tab>
            </v-tabs>
        </template>
    </premium-tool-header>
</template>

<script>
    import PremiumToolHeader from "../headers/PremiumToolHeader.vue";
    import {AppParameters} from "../../AppParameters";

    export default {
        name: 'PremiumAnswersHeader',
        components: {PremiumToolHeader},
        data() {
            return {
                quickAskEnabled: AppParameters.quick_consult_enabled
            }
        },
        computed: {
            activeTab: {
                get() {
                    return this.$store.getters.getPremiumAnswersTab
                },
                set(value) {
                    this.$store.commit('setPremiumAnswersTab', value)
                }
            }
        },
        methods: {
            goToDatabase() {
                window.location = this.getRoute('premium_answers');
            },
            goToQuickConsult() {
                window.location = this.getRoute('quick_consult');
            }
        },
        created() {
            const path = window.location.pathname;

            if (path.startsWith('/quick-consult')) {
                this.activeTab = 1;
            } else {
                this.activeTab = 0;
            }
        }
    }
</script>

<style scoped>
    .v-tab--active {
        background: unset !important;
        color: var(--v-primary-base) !important;
    }
</style>
