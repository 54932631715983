<template>
    <v-container class="container mt-13">
        <div class="search-text-button">
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-card fab outlined @click="show = !show" v-bind="attrs" v-on="on" v-show="show">
                        <v-icon large>
                            mdi-chevron-left
                        </v-icon>
                    </v-card>
                </template>
                <span class="tooltip-content">Click to toggle Search Content</span>
            </v-tooltip>
        </div>
        <v-card v-show="!show" max-width="480" max-height="40">
            <v-row justify="space-between" dense>
                <v-col cols="auto" class="pa-0">
                    <v-text-field
                        v-model="searchQuery"
                        solo
                        flat
                        dense
                        label="Search text..."
                        @keydown.enter="searchForward"
                        type="text"
                        height="15"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="auto" class="pa-0 pt-2 pr-3" v-show="searchQuery && defaultCount">
                    <span class="caption">{{ currentCount }}</span> &#x2f; <span class="caption">{{ defaultCount }}</span>
                </v-col>
                <v-col cols="auto" class="pa-0 pt-1">
                    <span class="vl pa-1"></span>
                    <v-btn icon color="black" @click="clearMessage">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                    <v-btn icon color="black" @click="searchBackward">
                        <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-btn icon color="black" @click="searchForward">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn outlined class="rounded" icon color="black" @click="closeTextSearch">
                        <v-icon large>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import Mark from "mark.js";

export default {
    data: () => ({
        searchQuery: "",
        defaultCount: 0,
        currentCount: 0,
        show: false,
        backward: false,
        markInstance: null,
        markElements: null
    }),

    watch: {
        searchQuery(newValue) {
            if (newValue.trim()) {
                this.highlightText();
            } else {
                this.clearMessage();
            }
        }
    },
    methods: {
        highlightText() {
            // Clear previous highlights
            this.markInstance.unmark({
                done: () => {
                    if (this.searchQuery) {
                        this.markInstance.mark(this.searchQuery, {
                            className: "highlight",
                            separateWordSearch: false,
                            done: ()=> {
                                this.markElements = document.querySelectorAll("mark");
                                this.defaultCount = this.markElements.length;
                                this.currentCount = 1;
                            }
                        });
                    }
                },
            });
        },
        updateCurrentCount() {
            if (this.backward) {
                this.currentCount =
                    this.currentCount === 1 ? this.defaultCount : this.currentCount - 1;
            } else {
                this.currentCount =
                    this.currentCount === this.defaultCount ? 1 : this.currentCount + 1;
            }
        },

        scrollToHighlight() {
            const element = this.markElements[this.currentCount - 1];
            element?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        },

        restartSearch() {
            this.currentCount = this.backward ? this.defaultCount : 1;
            this.scrollToHighlight();
        },

        searchForward() {
            this.backward = false;
            this.updateCurrentCount();
            this.scrollToHighlight();
        },

        searchBackward() {
            this.backward = true;
            this.updateCurrentCount();
            this.scrollToHighlight();
        },

        clearMessage() {
            this.searchQuery = '';
            this.currentCount = 0;
            this.defaultCount = 0;
            this.markInstance.unmark(); // Clear highlights
        },

        closeTextSearch() {
            this.clearMessage();
            this.show = true;
        },
    },
    mounted(){
        const section = document.getElementById("app");
        this.markInstance = new Mark(section);
    }
};
</script>

<style scoped>
.container {
    top: 85px;
    width: auto;
    position: fixed;
    z-index: 10000;
    right: 0;
}

.search-text-button {
    z-index: 200;
    right: 0;
    position: fixed;
    top: 140px;
}

.vl {
    border-left: 1px solid rgb(64, 65, 64);
    height: 35px;
}

.highlight {
    background-color: yellow;
    color: black;
    border-radius: 3px;
    padding: 2px;
}
</style>
