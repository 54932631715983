<template>
    <div v-if="isChapters">
        <ul class="chapters-list list-none pl-0">
            <template
            v-for="(chapter, index) in chapters">
                <li :key="'chapter'+index" :id="setActiveChapterID(chapter)"  v-if="chapter.link || chapter.title"
                    class="ma-0">
                    <v-row align="center"
                    class="toc-chapter-item"
                    :style="`padding-left: ${padding}px;`"
                    :class="{
                        'monotonesLightGreen': isActiveItem(chapter) || focusedChapter == chapter?.content_id,
                        'disabled': disableToc,
                    }">
                        <v-col
                            v-if="isXML"
                            cols="auto"
                            class="pa-0 px-1">
                            <v-btn
                            class="item-pointers-icon"
                            icon
                            small
                            @click="showSubSection(chapter.content_id)"
                            @focus="makeFocus(chapter)"
                            role="button" 
                            :aria-label="`Chapter Item Pointer Icons ${chaptersTitle(chapter)}`"
                            :tabindex="isSubSection(chapter.sub_sections) &&
                            'COPYRIGHT' != chaptersTitle(chapter) &&
                            'PREFACE' != chaptersTitle(chapter) ? 0 : -1">
                                <v-icon
                                    v-if="isSubSection(chapter.sub_sections) &&
                                    'COPYRIGHT' != chaptersTitle(chapter) &&
                                    'PREFACE' != chaptersTitle(chapter)"
                                    :id="'chapter-toggle-'+index"
                                    :class="isActiveItem(chapter) ? 'primary--text' : 'grey--text text--darken-4'">
                                        {{ isExpanded(chapter.content_id) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            tabindex="0"
                            class="pa-0 cp toc-chapter-item-focused"
                            :class="{
                                'xs11': isXML && $store.getters.getLoggedIn == 1,
                                'xs12': !isXML || $store.getters.getLoggedIn == 0,
                            }"
                            @focus="makeFocus(chapter)"
                            @click="setActiveSection(chapter)"
                            @contextmenu.prevent="showContextMenu(chapter, $event)"
                            @keydown.space.prevent="setActiveSection(chapter)"
                            @keydown.enter.prevent="setActiveSection(chapter)">
                            <p
                            class="mb-0 py-2 body-2"
                            :class="{
                                'pl-6': !isXML,
                            }"
                            :key="index">
                                <a
                                    class="fs-14"
                                    href="javascript:void(0);"
                                    :class="isActiveItem(chapter) ? 'primary--text' : 'grey--text text--darken-4'"
                                    v-html="chaptersTitle(chapter)">
                                </a>
                            </p>
                        </v-col>
                    </v-row>
                    <template v-if="isSubSection(chapter.sub_sections) && isExpanded(chapter.content_id)">
                        <chapters
                        class="nested-lists"
                        :key="'subsection'+index"
                        :chapters_data="subSections(chapter.sub_sections)"
                        :sub_section="true">
                        </chapters>
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
    import Legend from './../inc/mixins/legend';
    import {DomUtils} from "../../../util/DomUtils";
    import {StringUtil} from "../../../util/StringUtil";

    export default {
        data() {
            return {
                active_chapter_id: '',
                expanded_active: [],
                pageLoaded: false,
            }
        },
        mixins: [
            Legend
        ],
        name: 'chapters',
        props: {
            chapters_data: {
                type: Array,
                required: false
            },
            sub_section: {
                type: Boolean,
                required: false
            },
            notes: {
                type: Boolean,
                required: false
            },
            padding: {
                required: false,
                type: Number,
                default: 16
            },
            disableToc: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            isChapters() {
                if (_.size(this.chapters) > 0) {
                    return true;
                }
            },
            isSubSection() {
                return (sub_sections) => {
                    if (_.size(sub_sections) > 0) {
                        return true;
                    }
                }
            },
            expandedActive() {
                return this.$store.getters.getExpandedActive;
            },
            isExpanded() {
                return (id) => {
                    let sub_sections = _.find(this.expandedActive, {content_id: _.toNumber(id)});
                    if (sub_sections != undefined && sub_sections.active == true) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            chapters() {
                return this.chapters_data;
            },
            subSections() {
                return (sub_sections) => {
                    return Object.keys(sub_sections).map(key => (sub_sections[key]));
                }
            },
            focusedChapter: {
                get() {
                    return this.$store.getters.getFocusedTocSection;
                },
                set() {

                }
            },

        },
        watch: {
            active_chapter_id: function(val) {
                let scroll_elem;
                let element = document.getElementById(val);
                if (this.$vuetify.breakpoint.xsOnly) {
                    scroll_elem = document.getElementById('chapter-wrapper-mobile');
                } else {
                    scroll_elem = document.getElementById('chapter-wrapper');
                }
                this.$scrollTo(element, {duration: 500, container: scroll_elem});
            }
        },
        methods: {
            setActiveChapterID(chapter) {
                let id = this.isXML ? chapter.content_id : chapter.id;

                if (!this.sub_section && id === this.activeChapter) {
                    this.active_chapter_id = 'section-list-'+id;
                    return this.active_chapter_id;
                }
            },
            showSubSection(id) {
                let expand_active_item = _.find(this.expandedActive, {content_id: _.toNumber(id)});
                if (expand_active_item == undefined) {
                    this.$store.commit('addExpandedActive', {content_id: id, active: true});
                } else {
                    this.$store.commit('editExpandedActive', id);
                }
            },
            isActiveItem(chapter){
                return ((this.isActivePDFChapter(chapter) || this.isActiveSection(chapter)) && !this.pageLoaded) ||
                    this.$store.getters.getSelectedContentId == chapter.content_id
            },
            setActiveSection(chapter){
                this.pageLoaded = true;
                this.$store.commit('setSelectedContentId', chapter.content_id);
                this.showContent(chapter);

                // sync page title
                this.updatePageTitleFromChapter(chapter);
            },
            makeFocus(chapter) {
                const id = this.isXML ? chapter.content_id : chapter.id;
                this.$store.commit('setFocusedSection', id);
            },
            updatePageTitleFromChapter(chapter) {
                const chapterSlug = chapter.chapter_slug;
                const displayTitle = this.$store.getters.getDocumentInfo.display_title;

                if (chapterSlug && displayTitle) {
                    const chapterClean = StringUtil.slugToTitle(chapterSlug);
                    DomUtils.updatePageTitle(`${chapterClean} - ${displayTitle}`);
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.nested-lists {
    .chapters-list {
        li {
            .toc-chapter-item {
                margin-left: 0 !important;
            }
        }
    }
}
.item-pointers-icon {
    &:not(.v-btn--text) {
        &:not(.v-btn--outlined) {
            &:focus {
                .v-btn__content {
                    i.v-icon {
                        background-color: #797976;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
</style>
