var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? _c("search-header", {
            attrs: {
              "total-results": _vm.pagination.totalResults,
              "search-term": _vm.search,
              filters: _vm.filters,
              "title-label": "graphic",
            },
            on: { showModal: () => (_vm.filtersDialog = true) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { staticClass: "mt-4", attrs: { outlined: "" } },
                    [_c("v-card-text", [_c("loading-linear")], 1)],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            _vm._l(_vm.results, function (item, key) {
              return _c(
                "v-col",
                { key: key, attrs: { sm: "3" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "fill-height",
                      attrs: { outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.getGraphicDetails(item)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img-block-height",
                          on: {
                            contextmenu: (event) =>
                              _vm.handleRightClick(event, item.document_sku),
                          },
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticStyle: {
                                position: "absolute",
                                "z-index": "2",
                                top: "2px",
                                right: "2px",
                              },
                              attrs: { color: "white", size: "40" },
                            },
                            [
                              _c("access-icon", {
                                attrs: {
                                  "access-level": _vm.getAccessLevel(
                                    item.document_sku,
                                    item.content_access_level_id
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-center" }, [
                            _c("img", {
                              attrs: {
                                src: item.figure_url,
                                alt:
                                  item.alt_text.length > 0
                                    ? item.alt_text
                                    : `${item.section_title} Graphic`,
                                height: "160px",
                              },
                              on: { error: _vm.loadPlaceholderImage },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          item.section_title
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "body-2 mb-0 font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.section_title) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  !_vm.isMobilePort
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color:
                                                              "primaryLight primary--text font-weight-bold",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-truncate",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  item.document_title
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "tooltip-content" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.document_title) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              "primaryLight primary--text font-weight-bold",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-truncate" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.document_title) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
      _vm._v(" "),
      _vm.hasMoreResults
        ? _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        text: "",
                        color: "primary",
                        loading: _vm.fetchingResults,
                      },
                      on: { click: _vm.showMore },
                    },
                    [
                      _vm._v("\n                Show More\n                "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          "\n                    mdi-chevron-down\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-dialog", {
        attrs: {
          "is-open": _vm.premiumRequiredDialog,
          width: 600,
          "border-style": "",
          buttons: false,
        },
        on: { closed: () => (this.premiumRequiredDialog = false) },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-3", attrs: { color: "accent2" } },
                  [_vm._v("\n                mdi-creation\n            ")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                Subscribe to Premium\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "mx-auto",
                    attrs: {
                      src: _vm.asset(
                        require("../../../images/graphics_placeholder.png")
                      ),
                      alt: "ICC Digital Codes Premium",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c(
                        "h3",
                        { staticClass: "font-weight-regular text-center my-4" },
                        [
                          _vm._v(
                            "\n                        You need a Premium subscription to "
                          ),
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.graphicDetails.document_title)),
                          ]),
                          _vm._v(
                            " to view this graphic.\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-1",
                            attrs: { outlined: "", depressed: "" },
                            on: {
                              click: function ($event) {
                                return _vm.redirectToLearnMore()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Learn More\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-1",
                            attrs: { depressed: "", color: "accent2" },
                            on: {
                              click: function ($event) {
                                return _vm.openSubscribeToPremiumModal(
                                  _vm.graphicDetails.document_id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Subscribe To Premium\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("confirm-dialog", {
        attrs: { "is-open": _vm.dialog, width: 800 },
        on: { closed: () => (this.dialog = false) },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.graphicDetails.section_title) +
                    "\n        "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("v-container", [
                  _c(
                    "section",
                    { staticClass: "view-graphic-modal__section" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "view-graphic-model__image pb-2",
                          on: {
                            contextmenu: (event) =>
                              _vm.handleRightClick(
                                event,
                                _vm.graphicDetails.document_sku
                              ),
                          },
                        },
                        [
                          _c("v-img", {
                            staticStyle: {
                              "max-height": "40vh",
                              width: "auto",
                              "object-fit": "contain",
                            },
                            attrs: {
                              src: _vm.graphicDetails.figure_url,
                              alt:
                                _vm.graphicDetails.alt_text.length > 0
                                  ? _vm.graphicDetails.alt_text
                                  : `${_vm.graphicDetails.section_title} Graphic`,
                              "max-height": "300px",
                              contain: "",
                            },
                            on: { error: _vm.loadPlaceholderImage },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "view-graphic-modal__content v-card v-sheet theme--light v-card--flat mt-4 pa-3 rounded-lg section-border",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "view-graphic-modal__content-body" },
                            [
                              _vm.dialogLoading
                                ? _c("div", [_c("loading-linear")], 1)
                                : _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.graphicDetails.content
                                      ),
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isNotEmptyObject(_vm.graphicDetails.breadcrumbs)
                            ? _c(
                                "div",
                                [
                                  _c("item-breadcrumb", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      meta: _vm.graphicDetails,
                                      documentId:
                                        _vm.graphicDetails.document_id,
                                      breadcrumbs:
                                        _vm.graphicDetails.breadcrumbs,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { outlined: "", color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.dialog = false
                      },
                    },
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: _vm.redirectLink, target: "_blank" },
                    on: {
                      click: function ($event) {
                        return _vm.logSearchClick(
                          _vm.actionTypeMessage.jumpToSection
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-2",
                        attrs: { loading: _vm.dialogLoading, color: "primary" },
                      },
                      [
                        _vm._v(
                          "\n                    Jump to Section\n                    "
                        ),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(
                            "\n                        mdi-open-in-new\n                    "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", scrollable: "" },
          model: {
            value: _vm.filtersDialog,
            callback: function ($$v) {
              _vm.filtersDialog = $$v
            },
            expression: "filtersDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("h3", [_vm._v("Graphics Filters")]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.filtersDialog = false
                        },
                      },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4" },
                [_c("graphics-search-filters")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.filtersDialog = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Close\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }