var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "toc-wrapper pa-0", attrs: { id: "toc-wrapper-container" } },
    [
      _c(
        "div",
        { staticClass: "legend" },
        [
          _c(
            "v-navigation-drawer",
            {
              class: {
                "pa-0": !this.$store.getters.getLoggedIn,
              },
              staticStyle: { "max-height": "unset" },
              attrs: {
                app: "",
                clipped: "",
                "mini-variant": _vm.tocMini,
                "mini-variant-width": "0",
                permanent: "",
                width: _vm.tocWidth(
                  _vm.$getConst("contentTypePdf") ==
                    _vm.documentInfo.content_type.name
                ),
                height: "100vh",
                "z-index": "45",
              },
              on: {
                "update:miniVariant": function ($event) {
                  _vm.tocMini = $event
                },
                "update:mini-variant": function ($event) {
                  _vm.tocMini = $event
                },
              },
              model: {
                value: _vm.legend,
                callback: function ($$v) {
                  _vm.legend = $$v
                },
                expression: "legend",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "resize",
                      rawName: "v-resize",
                      value: _vm.resizeChapterPanel,
                      expression: "resizeChapterPanel",
                    },
                  ],
                  attrs: { id: "chapter-wrap-header" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-4 pt-4 pb-2 fixed" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getBookTitle(
                                      _vm.documentInfo,
                                      _vm.documentInfo.is_astm ? false : true
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.isExam
                                ? _c("title-reference", {
                                    attrs: {
                                      currentBookId:
                                        _vm.documentInfo.document_id,
                                      examId: _vm.examId,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "div",
                              { staticClass: "mr-2" },
                              [
                                _vm.documentInfo &&
                                _vm.isSingleTitle(_vm.documentInfo) &&
                                "" === _vm.examId
                                  ? _c("favorite-icon", {
                                      attrs: {
                                        document_id:
                                          _vm.documentInfo.document_id,
                                        "show-message": false,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.documentInfo &&
                      _vm.isSingleTitle(_vm.documentInfo) &&
                      "" === _vm.examId
                        ? _c(
                            "v-row",
                            { attrs: { dense: "", align: "center" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0" },
                                [
                                  _c("adoption-profile", {
                                    attrs: { documentInfo: _vm.documentInfo },
                                  }),
                                  _vm._v(" "),
                                  _vm.documentInfo.supports_versions
                                    ? _c("title-reference", {
                                        attrs: {
                                          examId: "",
                                          "document-id":
                                            _vm.documentInfo.document_id,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.showTabs
                    ? _c(
                        "v-tabs",
                        {
                          staticClass: "codes-content-tabs",
                          attrs: { height: "40px", grow: "", color: "primary" },
                          model: {
                            value: _vm.activeTab,
                            callback: function ($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              class:
                                _vm.activeTab === 0
                                  ? "primary--text"
                                  : "grey--darken-1",
                            },
                            [
                              _vm.isContentRestricted(_vm.documentInfo) &&
                              "" === _vm.examId
                                ? _c("v-icon", { staticClass: "pr-1" }, [
                                    _vm._v(
                                      "\n                            mdi-lock-outline\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n                        Contents\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              class:
                                _vm.activeTab === 1
                                  ? "primary--text"
                                  : "grey--darken-1",
                              on: {
                                click: function ($event) {
                                  return _vm.showUserNotes()
                                },
                              },
                            },
                            [
                              _vm.freeView
                                ? _c("v-icon", { staticClass: "pr-1" }, [
                                    _vm._v(
                                      "\n                            mdi-lock-outline\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n                        Notes\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isCodeInsightsFilterEnabled() &&
                          _vm.showInsightTab
                            ? _c(
                                "v-tab",
                                {
                                  class:
                                    _vm.activeTab === 2
                                      ? "primary--text"
                                      : "grey--darken-1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showUserNotes()
                                    },
                                  },
                                },
                                [
                                  _vm.freeView
                                    ? _c("v-icon", { staticClass: "pr-1" }, [
                                        _vm._v(
                                          "\n                            mdi-lock-outline\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                        Insights\n                    "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "v-tabs",
                        {
                          attrs: { height: "40px", left: "", color: "primary" },
                        },
                        [
                          _c("v-tab", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        Contents\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "keep-alive",
                [
                  _vm.activeTab === 0
                    ? _c("chapter-panel", {
                        attrs: {
                          "document-info": _vm.documentInfo,
                          "exam-id": _vm.examId,
                        },
                      })
                    : _vm.activeTab === 1
                    ? _c("notes-panel", {
                        attrs: {
                          "free-view": _vm.freeView,
                          "document-info": _vm.documentInfo,
                        },
                      })
                    : _vm.activeTab === 2 &&
                      _vm.isCodeInsightsFilterEnabled() &&
                      _vm.showInsightTab
                    ? _c("panel-insights", {
                        attrs: {
                          codeInsightsData: _vm.codeInsightsData,
                          "free-view": _vm.freeView,
                          "document-info": _vm.documentInfo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      "" === _vm.examId ? _c("div", [_c("context-menu")], 1) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }